import React from 'react'
import Navbar from '../Navbar'
import Appstable from './Appstable'

export default function Appdashboard() {
    if (localStorage.getItem('access_token') === null) {
        window.location.href = '/'
      }
    return (
        <>
            <Navbar />
            <div className="container my-4 text-light text-center">
                <h1 className='display-5'>APP DASHBOARD</h1>
            </div>

            <Appstable />

        </>
    )
}
