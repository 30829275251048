import React from 'react';
import Navbar from '../Navbar';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Select Box Requirements
import { useState, useMemo } from 'react';
import Select from 'react-select';
import countryList from 'react-select-country-list';

import { AuthToken } from '../..';
// import Vpndashboard from './Vpndashboard';

export default function Addvpn() {


    const [privatekey, setPrivatekey] = useState('');
    const [address, setAddress] = useState('');
    const [dns, setDns] = useState('');
    const [publickey, setPublickey] = useState('');
    const [presharedkey, setPresharedkey] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [allowed_ips, setAllowed_ips] = useState('');
    const [country_value, setcountry_value] = useState('')
    const options = useMemo(() => countryList().getData(), [])

    const ChangeCountry = country_value => {
        setcountry_value(country_value)
        // console.log(country_value)
    }


    const AddVpnForm = async () => {
        // const vpn_data = {

        //     "privatekey": privatekey,
        //     "address": address,
        //     "dns": dns,
        //     "publickey": publickey,
        //     "presharedkey": presharedkey,
        //     "endpoints": endpoint,
        //     "allowed_ips": allowed_ips,
        //     "country": country_value.value

        // }

        const vpn_data = new FormData();
        vpn_data.append("privatekey", privatekey);
        vpn_data.append("address", address);
        vpn_data.append("dns", dns);
        vpn_data.append("publickey", publickey);
        vpn_data.append("presharedkey", presharedkey);
        vpn_data.append("endpoints", endpoint);
        vpn_data.append("allowed_ips", allowed_ips);
        vpn_data.append("country", country_value.value);


        //     try{
        //     const data = await axios.post('/frontend/vpn/' ,vpn_data , {headers:{Authorization :AuthToken}} )
        //     // console.log(data)
        //     // window.location.href = "/vpndashboard";
        //     return (<Vpndashboard/>)
        //     alert('Vpn Added Successfully .')
        // }catch{
        //     alert('Server Not Responding ..')
        // }

        axios.post('/frontend/vpn/', vpn_data, { headers: { Authorization: AuthToken } })
            .then((resdata) => {
                alert('Vpn Added Successfully .')
            }
            ).catch((err) => { alert('Server Not responding.') })
    }




    return (

        <>
            <Navbar />


            <div className="container-fluid my-5 p-5 bg-light" id='AddVpnFormStyle'>
                <div className="container-fluid my-3 text-center">
                    <h1 className='display-6 text-dark'>Add Vpn</h1>
                </div>

                <div className="container-fluid">
                    <form onSubmit={AddVpnForm} action='/vpndashboard'>
                        {/* Privatekey */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='privatekey' name='privatekey' placeholder='Enter Private-Key' required onChange={(e) => setPrivatekey(e.target.value)} />
                        </div>

                        {/* Address  */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='address' name='address' placeholder='Enter IP-Address IPv4 : IPv6' required onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        {/* D.N.S  */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='dns' name='dns' placeholder='Enter D.N.S' required onChange={(e) => setDns(e.target.value)} />
                        </div>

                        {/* Public Key */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='publickey' name='publickey' placeholder='Enter Public-key' required onChange={(e) => setPublickey(e.target.value)} />
                        </div>

                        {/* Preshared-key */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='presharedkey' name='presharedkey' placeholder='Enter Preshared-key' required onChange={(e) => setPresharedkey(e.target.value)} />
                        </div>

                        {/* Endpoints */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='endpoint' name='endpoint' placeholder='Enter Endpoint' required onChange={(e) => setEndpoint(e.target.value)} />
                        </div>

                        {/* Allowed Ips */}
                        <div className="mb-3">
                            <input type="text" className='form-control' id='allowed_ips' name='allowed_ips' placeholder='Enter Allowed-IP' required onChange={(e) => setAllowed_ips(e.target.value)} />
                        </div>

                        {/* Country */}
                        <div className="mb-4">
                            {/* <input type="text" className='form-control' id='country' name='country' placeholder='Select Country' required /> */}
                            <Select options={options} value={country_value} onChange={ChangeCountry} id='country' name='country' />
                        </div>



                        <div className="mb-3 text-center">
                            <input type="submit" value="ADD VPN" className='btn btn-primary px-4 me-2' />
                            <Link onClick={()=>{
                                window.history.back()
                            }} className='btn btn-secondary'>Cancle</Link>
                        </div>
                    </form>




                </div>



            </div>




        </>
    )
}
