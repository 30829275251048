import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Interceptor/axios';
import axios from 'axios';


// axios.defaults.baseURL = 'http://127.0.0.1:8000'
// export const AuthToken = 'token d4bc7d48da6096ab6463af3f0495033ed3c69c27'

// // local server info
// axios.defaults.baseURL = 'http://192.168.0.176:8000'
// export const AuthToken = 'token 0c0319dd6e50814d6fdb9f8ff63125bfa7c0043c';

// live server info
// axios.defaults.baseURL = 'http://64.227.146.222'
// export const AuthToken = 'token 6268957ee904f484cfc1232770fd2847d7368764';


// axios.defaults.baseURL = 'http://192.168.1.62:8000'
// export const AuthToken = 'token 6268957ee904f484cfc1232770fd2847d7368764'

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const AuthToken = process.env.REACT_APP_API_KEY;

// console.log('process.env.REACT_APP_API_URL ',process.env.REACT_APP_API_URL)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
