// import favicon from '../Images/Logo/favicon.ico';
// import usericon from '../Images/Logo/usericon.png';
// import logout from '../Images/Logo/logout.png';


// import React from 'react';
// import { Link } from 'react-router-dom';
// import { useEffect } from 'react';
// import { useState } from 'react';

// export default function Navbar() {
//     const [usern , setUsern] = useState('')
//     useEffect(()=>{

//         if (localStorage.getItem('username')){

//             setUsern(localStorage.getItem('username'))
//         }
//         else{
//             setUsern(localStorage.setItem('username','admin'))
//         }
//     } ,[])



//     return (
//         <>
//             <nav className="navbar navbar-expand-lg navbar-light bg-light">

//                 <div className="container-fluid">

//                     <Link to="/vpndashboard" className="navbar-brand"  ><img src={favicon} height='40px' alt='No Logo' /></Link>

//                     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//                         <span className="navbar-toggler-icon"></span>
//                     </button>
//                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                         <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                             <li className="nav-item">

//                                 <Link to="/vpndashboard" className="nav-link active" aria-current="page" >Dashboard</Link>
//                             </li>
//                             <li className="nav-item">
//                                 <Link to="/appdashbord" className="nav-link" >Apps</Link>
//                             </li>

//                             <li className="nav-item">
//                                 <Link to="/appdashbord" className="nav-link" >Marketing Panel</Link>
//                             </li>

//                         </ul>
//                         <form className="d-flex">
//                             <div className='mt-2'>
//                             <img src={usericon} height='30px' alt='No Logo' className='me-2' />
//                             <span>{usern}</span>
//                             </div>
//                             <Link to="/logout" className="nav-link" ><img src={logout} height='30px' alt='No Logo' /></Link>
//                         </form>
//                     </div>
//                 </div>
//             </nav>


//         </>
//     )
// }



// import favicon from '../Images/Logo/favicon.ico';
// import usericon from '../Images/Logo/usericon.png';
// import logout from '../Images/Logo/logout.png';

// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';

// export default function Navbar() {
//     const [usern, setUsern] = useState('');
//     const [activeLink, setActiveLink] = useState('/vpndashboard');

//     useEffect(() => {
//         if (localStorage.getItem('username')) {
//             setUsern(localStorage.getItem('username'));
//         } else {
//             setUsern(localStorage.setItem('username', 'admin'));
//         }
//     }, []);

//     const location = useLocation();

//     useEffect(() => {
//         setActiveLink(location.pathname);
//     }, [location.pathname]);

//     return (
//         <>
//             <nav className="navbar navbar-expand-lg navbar-light bg-light">
//                 <div className="container-fluid">
//                     <Link to="/vpndashboard" className="navbar-brand">
//                         <img src={favicon} height='40px' alt='No Logo' />
//                     </Link>
//                     <button
//                         className="navbar-toggler"
//                         type="button"
//                         data-bs-toggle="collapse"
//                         data-bs-target="#navbarSupportedContent"
//                         aria-controls="navbarSupportedContent"
//                         aria-expanded="false"
//                         aria-label="Toggle navigation"
//                     >
//                         <span className="navbar-toggler-icon"></span>
//                     </button>
//                     <div className="collapse navbar-collapse" id="navbarSupportedContent">
//                         <ul className="navbar-nav me-auto mb-2 mb-lg-0">
//                             <li className={`nav-item ${activeLink === '/vpndashboard' ? 'active' : ''}`}>
//                                 <Link to="/vpndashboard" className={`nav-link ${activeLink === '/vpndashboard' ? 'font-weight-bold' : ''}`}>
//                                     Dashboard
//                                 </Link>
//                             </li>
//                             <li className={`nav-item ${activeLink === '/appdashbord' ? 'active' : ''}`}>
//                                 <Link to="/appdashbord" className={`nav-link ${activeLink === '/appdashbord' ? 'font-weight-bold' : ''}`}>
//                                     Apps
//                                 </Link>
//                             </li>
//                             <li className={`nav-item ${activeLink === '/marketingpanel' ? 'active' : ''}`}>
//                                 <Link to="/marketingpanel" className={`nav-link ${activeLink === '/appdashbord' ? 'font-weight-bold' : ''}`}>
//                                     Marketing Panel
//                                 </Link>
//                             </li>
//                         </ul>
//                         <form className="d-flex">
//                             <div className='mt-2'>
//                                 <img src={usericon} height='30px' alt='No Logo' className='me-2' />
//                                 <span>{usern}</span>
//                             </div>
//                             <Link to="/logout" className="nav-link">
//                                 <img src={logout} height='30px' alt='No Logo' />
//                             </Link>
//                         </form>
//                     </div>
//                 </div>
//             </nav>
//         </>
//     );
// }





// import favicon from '../Images/Logo/favicon.ico';
// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// const Navbar = () => {
//     const [usern, setUsern] = useState('');
//     const [activeLink, setActiveLink] = useState('/vpndashboard');

//     useEffect(() => {
//         if (localStorage.getItem('username')) {
//             setUsern(localStorage.getItem('username'));
//         } else {
//             setUsern(localStorage.setItem('username', 'admin'));
//         }
//     }, []);

//     const location = useLocation();

//     useEffect(() => {
//         setActiveLink(location.pathname);
//     }, [location.pathname]);

//     return (
//         <AppBar position="static">
//             <Toolbar>
//                 <Typography variant="h6" component={Link} to="/vpndashboard" sx={{ textDecoration: 'none', color: 'inherit', flexGrow: 1 }}>
//                     <img src={favicon} height='40px' alt='No Logo' style={{ marginRight: '10px' }} />
//                     My App
//                 </Typography>
//                 <Button component={Link} to="/vpndashboard" color="inherit" className={activeLink === '/vpndashboard' ? 'active' : ''}>
//                     Dashboard
//                 </Button>
//                 <Button component={Link} to="/appdashbord" color="inherit" className={activeLink === '/appdashbord' ? 'active' : ''}>
//                     Apps
//                 </Button>
//                 <Button component={Link} to="/marketingpanel" color="inherit" className={activeLink === '/marketingpanel' ? 'active' : ''}>
//                     Marketing Panel
//                 </Button>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="body1" sx={{ marginRight: '10px' }}>{usern}</Typography>
//                     <IconButton component={Link} to="/logout" color="inherit">
//                         <ExitToAppIcon />
//                     </IconButton>
//                 </div>
//             </Toolbar>
//         </AppBar>
//     );
// };

// export default Navbar;





// import favicon from '../Images/Logo/favicon.ico';
// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// const Navbar = () => {
//     const [usern, setUsern] = useState('');
//     const [activeLink, setActiveLink] = useState('/vpndashboard');

//     useEffect(() => {
//         if (localStorage.getItem('username')) {
//             setUsern(localStorage.getItem('username'));
//         } else {
//             setUsern(localStorage.setItem('username', 'admin'));
//         }
//     }, []);

//     const location = useLocation();

//     useEffect(() => {
//         setActiveLink(location.pathname);
//     }, [location.pathname]);

//     return (
//         <AppBar position="static" sx={{ backgroundColor: 'white' }}>
//             <Toolbar sx={{ justifyContent: 'space-between' }}>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="h6" component={Link} to="/vpndashboard" sx={{ textDecoration: 'none', color: 'inherit', marginRight: '20px' }}>
//                         <img src={favicon} height='40px' alt='No Logo' style={{ marginRight: '10px' }} />

//                     </Typography>
//                     <Button component={Link} to="/vpndashboard" color="inherit" className={activeLink === '/vpndashboard' ? 'active' : ''}>
//                         Dashboard
//                     </Button>
//                     <Button component={Link} to="/appdashbord" color="inherit" className={activeLink === '/appdashbord' ? 'active' : ''}>
//                         Apps
//                     </Button>
//                     <Button component={Link} to="/marketingpanel" color="inherit" className={activeLink === '/marketingpanel' ? 'active' : ''}>
//                         Marketing Panel
//                     </Button>
//                 </div>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="body1" sx={{ marginRight: '10px' }}>{usern}</Typography>
//                     <IconButton component={Link} to="/logout" color="inherit">
//                         <ExitToAppIcon />
//                     </IconButton>
//                 </div>
//             </Toolbar>
//         </AppBar>
//     );
// };

// export default Navbar;




// import favicon from '../Images/Logo/favicon.ico';
// import React, { useEffect, useState } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// const Navbar = () => {
//     const [usern, setUsern] = useState('');
//     const [activeLink, setActiveLink] = useState('/vpndashboard');

//     useEffect(() => {
//         if (localStorage.getItem('username')) {
//             setUsern(localStorage.getItem('username'));
//         } else {
//             setUsern(localStorage.setItem('username', 'admin'));
//         }
//     }, []);

//     const location = useLocation();

//     useEffect(() => {
//         setActiveLink(location.pathname);
//     }, [location.pathname]);

//     return (
//         <AppBar position="static" sx={{ backgroundColor: 'white' }}>
//             <Toolbar sx={{ justifyContent: 'space-between' }}>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="h6" component={Link} to="/vpndashboard" sx={{ textDecoration: 'none', color: 'primary.main', marginRight: '20px' }}>
//                         <img src={favicon} height='40px' alt='No Logo' style={{ marginRight: '10px' }} />

//                     </Typography>
//                     <Button component={Link} to="/vpndashboard" color="inherit" className={activeLink === '/vpndashboard' ? 'active' : ''} sx={{ color: 'primary.main' }}>
//                         Dashboard
//                     </Button>
//                     <Button component={Link} to="/appdashbord" color="inherit" className={activeLink === '/appdashbord' ? 'active' : ''} sx={{ color: 'primary.main' }}>
//                         Apps
//                     </Button>
//                     <Button component={Link} to="/marketingpanel" color="inherit" className={activeLink === '/marketingpanel' ? 'active' : ''} sx={{ color: 'primary.main' }}>
//                         Marketing Panel
//                     </Button>
//                 </div>
//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <Typography variant="body1" sx={{ marginRight: '10px', color: 'primary.main' }}>{usern}</Typography>
//                     <IconButton component={Link} to="/logout" color="inherit">
//                         <ExitToAppIcon />
//                     </IconButton>
//                 </div>
//             </Toolbar>
//         </AppBar>
//     );
// };

// export default Navbar;






import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import favicon from '../Images/Logo/favicon.ico';
import usericon from '../Images/Logo/usericon.png';

const Navbar = () => {
    const [usern, setUsern] = useState('');
    const [activeLink, setActiveLink] = useState('/vpndashboard');

    useEffect(() => {
        if (localStorage.getItem('username')) {
            setUsern(localStorage.getItem('username'));
        } else {
            setUsern(localStorage.setItem('username', 'admin'));
        }
    }, []);

    const location = useLocation();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    return (
        <AppBar position="static" sx={{ backgroundColor: 'white' }}>
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" component={Link} to="/vpndashboard" sx={{ textDecoration: 'none', color: 'primary.main', marginRight: '20px' }}>
                        <img src={favicon} height='40px' alt='No Logo' style={{ marginRight: '10px' }} />

                    </Typography>
                    <Button
                        component={Link}
                        to="/vpndashboard"
                        color="inherit"
                        className={activeLink === '/vpndashboard' ? 'active' : ''}
                        sx={{
                            color: 'primary.main',
                            ':hover': {
                                backgroundColor: 'primary.light', // Add the hover background color
                                color:"white"
                            },
                            textTransform: 'none'
                        }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        component={Link}
                        to="/appdashbord"
                        color="inherit"
                        className={activeLink === '/appdashbord' ? 'active' : ''}
                        sx={{
                            color: 'primary.main',
                            ':hover': {
                                backgroundColor: 'primary.light', // Add the hover background color
                                color:"white"
                            },
                            textTransform: 'none'
                        }}
                    >
                        Apps
                    </Button>
                    <Button
                        component={Link}
                        to="/marketingpanel"
                        color="inherit"
                        className={activeLink === '/marketingpanel' ? 'active' : ''}
                        sx={{
                            color: 'primary.main',
                            ':hover': {
                                backgroundColor: 'primary.light', // Add the hover background color
                                color:"white"
                            },
                            textTransform: 'none'
                        }}
                    >
                        Marketing Panel
                    </Button>
                    <Button
                        component={Link}
                        to="/drive2mediapanel"
                        color="inherit"
                        className={activeLink === '/drive2mediapanel' ? 'active' : ''}
                        sx={{
                            color: 'primary.main',
                            ':hover': {
                                backgroundColor: 'primary.light', // Add the hover background color
                                color:"white"
                            },
                            textTransform: 'none'
                        }}
                    >
                        Drive2Media Panel
                    </Button>
                </div>
                <Link to="/logout">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={usericon} height='30px' alt='No Logo' className='me-2' title='Click to logout' />
                        <Typography variant="body1" sx={{ marginX: '10px', color: 'primary.main' }} title='Click to logout' >{usern}</Typography>
                        <IconButton component={Link} to="/logout" color="inherit" sx={{ marginRight: '10px', color: 'primary.main' }} title='Click to logout'>
                            <ExitToAppIcon />
                        </IconButton>
                    </div>
                </Link>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
