import React from 'react';
import axios from 'axios';
import { useEffect } from 'react';


export default function Logout() {
  
  useEffect(() => {
    (async () => {
      try {
        // console.log('Logout Localstorage ',localStorage)
        
        const { data } = await axios.post('/auth/logout/', { 'refresh_token': localStorage.getItem('refresh_token') }, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access_token'),
            'Content-Type': 'application/json',
            'Accept':'application/json',
          }
        }, { withCredentials: true });
        // console.log(data)
        localStorage.clear();
        // axios.defaults.headers.common['Authorization'] = null;
        window.location.href = '/'
      } catch (e) {
        // console.log('logout not working', e)
        window.location.href = '/'

      }
    })();
  }, []);
  return (
    <div></div>
  )
}
