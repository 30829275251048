import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { AuthToken } from '../..';
import Multiselect from 'multiselect-react-dropdown';




export default function Addapps() {


    const [allvpn, setAllvpn] = useState([]) // store vpn data from api 
    const [options, setOptions] = useState([]) // store vpn data from api 


    // Handle App Upload 
    const [appLogo, setAppLogo] = useState('');
    const [appName, setAppName] = useState('');
    const [packageName, setPackageName] = useState('');
    const [counrty, setCountry] = useState('')

    let countryServer = new Array();

    useEffect(() => {
        axios.get('/frontend/vpn/', { headers: { Authorization: AuthToken } })
            .then((Vpnresp) => {

                setAllvpn(Vpnresp.data);
                // document.getElementById(`is_enable${Vpnresp.data.id}`).checked;
                // console.log('allvpn :',Vpnresp.data)

            })
            .catch(() => {
                alert('Server Not responding to display data ')
            })
            
    }, [])

    const AddServer = () => {
        allvpn.forEach(country => { countryServer.push(country.country) })

        let uniqueServer = countryServer.filter((item, i, ar) => ar.indexOf(item) === i)
        setOptions(uniqueServer)
    }


    // Post App 

    // console.log('Options  ',options)
    const PostApps = () => {
        const app_logo = document.getElementById('applogo').value;
        const app_name = document.getElementById('appname').value;
        const package_name = document.getElementById('packagename').value;
        // const multi_select = document.getElementById('multiselect').value;

        const applogoMsg = document.getElementById('applogoMsg');
        const appnameMsg = document.getElementById('appnameMsg');
        const packagenameMsg = document.getElementById('packagenameMsg');
        const multiselectMsg = document.getElementById('multiselectMsg');

        // console.log(app_logo)
        // console.log(app_name)
        // console.log(package_name)
        // console.log(multi_select)

        if (!applogoMsg || !app_name || !package_name || !counrty || applogoMsg == "" || app_name=="" || package_name =="" || counrty=="") {

            // applogo  
            if (!app_logo || applogoMsg == "") {
                applogoMsg.innerHTML = "Please Select Logo";
            } else {
                applogoMsg.innerHTML = "";
            }

            // appname  
            if (!app_name || app_name=="" ) {
                appnameMsg.innerHTML = "Enter App Name";
            } else {
                appnameMsg.innerHTML = "";
            }

            // packagename  
            if (!package_name || package_name =="") {
                packagenameMsg.innerHTML = "Enter Package Name";
            } else {
                packagenameMsg.innerHTML = "";

            }


            // Country  
             if (!counrty || counrty=="") {
                multiselectMsg.innerHTML = "Select Server";
            } else {
                multiselectMsg.innerHTML = "";

            }



        } else {

            
            const formData = new FormData();
            formData.append('applogo', appLogo);
            formData.append('appname', appName);
            formData.append('packagename', packageName);
            formData.append('country', counrty)

            // console.log(formData)
            axios.post('/frontend/app/', formData, { headers: { Authorization: AuthToken } })
                .then((appResp) => {
                    // console.log(appResp.data)
                    if (appResp.data !== null) {
                        alert('Application Added Successfully .')
                        window.location.reload()


                    }
                })
                .catch((err) => { alert('Server Not responding ') })

        }




        

    }



    return (
        <>

            <tr>

                <td></td>
                <td style={{ width: '18%' }}><input onClick={AddServer} type="file" name="applogo" id="applogo" className='form-control' onChange={(e) => setAppLogo(e.target.files[0])} required />
                    <span id='applogoMsg' style={{ color: 'red' }}></span></td>

                <td style={{ width: '18%' }}><input onClick={AddServer} type="text" name="appname" id="appname" placeholder='App Name' className='form-control' onChange={(e) => { setAppName(e.target.value) }} required />
                    <span id='appnameMsg' style={{ color: 'red' }}></span></td>

                <td style={{ width: '18%' }}><input onClick={AddServer} type="text" name="packagename" id="packagename" placeholder='Package Name' className='form-control' onChange={(e) => { setPackageName(e.target.value) }} required />
                    <span id='packagenameMsg' style={{ color: 'red' }}></span></td>

                <td><Multiselect

                    isObject={false}
                    onRemove={(event) => { setCountry(event); }}
                    onSelect={(event) => { setCountry(event); }}
                    options={options}
                    required

                    showCheckbox
                />
                    <span id='multiselectMsg' style={{ color: 'red' }}></span>
                </td>


                <td><input type="submit" className='btn btn-success' value='Add New' onClick={PostApps} /></td>


            </tr>


        </>
    )
}
