
import './App.css';
import Login from './Components/Login';
import Vpndashboard from './Components/Vpn/Vpndashboard';
import Appdashbord from './Components/Apps/Appdashboard'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Logout from './Components/Logout';
import Addvpn from './Components/Vpn/Addvpn';
import Updatevpn from './Components/Vpn/Updatevpn';
import Appstable from './Components/Apps/Appstable';
import Addapps from './Components/Apps/Addapps';
import Updateapp from './Components/Apps/Updateapp';
import MarketingDashboard from './Components/Marketing/MarketingDashboard';
import DriveMediaDashboard from './Components/DriveMedia/DriveMediaDashboard';


function App() {
  
  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route exact path='/' element={<Login />} ></Route>
          <Route exact path='/vpndashboard' element={<Vpndashboard />} ></Route>
          <Route exact path='/appdashbord' element={<Appdashbord />} ></Route>
          <Route exact path='/logout' element={<Logout />} ></Route>
          <Route exact path='/addvpn' element={<Addvpn />} ></Route>
          <Route exact path='/updatevpn' element={<Updatevpn />} ></Route>
          <Route exact path='/appstable' element={<Appstable />} ></Route>
          <Route exact path='/addapps' element={<Addapps />} ></Route>
          <Route exact path='/updateapp' element={<Updateapp />} ></Route>

          {/* Marketing Routs  */}
          <Route exact path='/marketingpanel' element={<MarketingDashboard />} ></Route>
          <Route exact path='/drive2mediapanel' element={<DriveMediaDashboard />} ></Route>



        </Routes>


      </BrowserRouter>
    </>
  );
}

export default App;


