import React from 'react';
import Navbar from '../Navbar';
import { useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Vpntabledata from './Vpntabledata';
import './Vpntable.css';



export default function Vpndashboard() {
  if (localStorage.getItem('access_token') === null) {
    window.location.href = '/'
  }

  useEffect(() => {

    if (localStorage.getItem('access_token') === null) {
      window.location.href = '/'
    }
    else {
      (async () => {
        try {

          const { data } = await axios.get('/auth/login/', {

            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access_token'),
              'Content-Type': 'application/json'
            }
          }
          );
          // (data.message)
          // setMessage(data.message);
        } catch (e) {
          console.log('not auth')
        }
      })()
    };
  }, []);

  // Vpn Delete 


  return (
    <>
      <Navbar />

      {/* <h3>{message}</h3> */}
      <div className="container-fluid my-3 text-light text-center">
        <h1 className='display-6'>VPN DASHBOARD</h1>
      </div>

      <div className="container mt-3 text-light text-end">
        <Link to='/addvpn' className='btn btn-primary'>Add Vpn</Link>
      </div>

      <div>
        <Vpntabledata />
      </div>


    </>
  )
}
