import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';

import { useEffect, useRef } from 'react';
import { AuthToken } from '../..';

import update from '../../Images/Logo/update.png';
import deleteLogo from '../../Images/Logo/delete.png';
import moment from 'moment/moment';

import countryList from 'react-select-country-list';
import { useState, useMemo } from 'react';
import Select from 'react-select';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';

// Table componenets

export default function Vpntabledata() {

  // states to use values 
  const [allvpn, setAllvpn] = useState([]) // store vpn data from api 

  const [url, setUrl] = useState('');
  const [privatekey, setPrivatekey] = useState('');
  const [address, setAddress] = useState('');
  const [dns, setDns] = useState('');
  const [publickey, setPublickey] = useState('');
  const [presharedkey, setPresharedkey] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [allowed_ips, setAllowed_ips] = useState('');
  const [country_value, setcountry_value] = useState("")
  // let country_value = "";
  const options = useMemo(() => countryList().getData(), [])
  const [loading, setLoading] = useState(true);
  const [vpnOptions, setVpnOptions] = useState([])









  let sr = 0;
  useEffect(() => {
    FetchVpnData()
  }, [])

  const FetchVpnData = () => {
    setLoading(true); // Show loading animation
    axios.get('/frontend/vpn/', { headers: { Authorization: AuthToken } })
      .then((Vpnresp) => {

        setAllvpn(Vpnresp.data);
        setTimeout(() => {

          setLoading(false);// Hide loading animation
          listOptions = setServerOptions(Vpnresp.data)
          setVpnOptions(listOptions)

        }, 500)

        // document.getElementById(`is_enable${Vpnresp.data.id}`).checked;
        // console.log('allvpn :',Vpnresp.data)

      })
      .catch(() => {
        alert('Server Not responding to display data ')
      })
  }



  // Enable or disable vpn function  update by check box
  const Enablevpn = (url, is_enable) => {

    let state = is_enable;
    if (state) {
      state = false;
      axios.patch(url, { "is_enable": state }, { headers: { Authorization: AuthToken } })
        .then((res) => { FetchVpnData() })
        .catch((err) => { alert('Unable to Switch') })
    } else {
      state = true
      axios.patch(url, { "is_enable": state }, { headers: { Authorization: AuthToken } })
        .then((res) => { FetchVpnData() })
        .catch((err) => { alert('Unable to Switch') })
    }
  }



  // this function is used to share the data 
  const ShareDataTOCanvas = async (url) => {

    try {
      const urldata = await axios.get(url, { headers: { Authorization: AuthToken } })
      console.log(urldata.data)
      setcountry_value({ value: "", label: "" });

      setPrivatekey(urldata.data.presharedkey);
      setAddress(urldata.data.address);
      setDns(urldata.data.dns);
      setPublickey(urldata.data.publickey);
      setPresharedkey(urldata.data.presharedkey);
      setEndpoint(urldata.data.endpoints);
      setAllowed_ips(urldata.data.allowed_ips);
      setUrl(urldata.data.url)
      ChangeCountry(urldata.data.country)
      console.log(country_value)


    } catch {
      alert('Server Down')
    }
    // alert(country_value)


  }


  // #Update Form 
  const UpdateForm = () => {

    const vpn_data = {

      "privatekey": privatekey,
      "address": address,
      "dns": dns,
      "publickey": publickey,
      "presharedkey": presharedkey,
      "endpoints": endpoint,
      "allowed_ips": allowed_ips,
      "country": country_value.value

    }

    axios.patch(url, vpn_data, { headers: { Authorization: AuthToken } })
      .then((res) => { ; FetchVpnData(); alert('Vpn Updated Successfully.') })
      .catch((err) => { alert('Server Not responding.') })



  }

  const ChangeCountry = country_value => {
    setcountry_value(country_value)
    console.log(country_value)

  }



  // ============ set server options start ==============

  const setServerOptions = (allvpn) => {
    let countryServer = new Array();

    allvpn.forEach(country => { countryServer.push(country.country) })

    let uniqueServer = countryServer.filter((item, i, ar) => ar.indexOf(item) === i)
    countryServer = uniqueServer

    return countryServer;
  }


  // ============ set server options end ==============




  // ================================= vpn filter options  componenet start 

  var listOptions = vpnOptions;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(item => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

    console.log('Selected options:', selectedOptions);
    // sideEffect()

  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);



  // ================================= vpn filter options table componenet end

  // const showAllVpn = (event) => {

  //   setIsOpen(false);
  //   FetchVpnData()

  // }
  // // console.log('allvpn ',allvpn)

  // const sideEffect = () => {


  //   if (selectedOptions !== []) {
  //     const filteredVPNs = allvpn.filter(vpn => selectedOptions.includes(vpn.country));
  //     setAllvpn(filteredVPNs)
  //     // console.log('filteredVPNs ', filteredVPNs)
  //   }
  //   else {
  //     setIsOpen(false);
  //     FetchVpnData()

  //   }

  // }

  // setAllvpn(()=>{
  //   selectedOptions.map(item) =>{
  //     allvpn.filter()
  //   }
  // })





  return (
    <>
      <div>
        <div className="container my-2 p-5 bg-light" id='outer-wraper' style={{ borderRadius: '10px', boxShadow: '2px 5px 15px gray' }}>



          {/* <div className="my-2">
            <div className="dropdown" ref={dropdownRef}>
              <button className="border dropbtn" title="Vpn filter options" onClick={toggleDropdown}>
                <ListOutlinedIcon />
              </button>
              {isOpen && (
                <div className="dropdown-content" >

                  <label className='select' style={{ cursor: 'pointer' }}>
                    <span style={{ paddingLeft: "10px" }} onClick={showAllVpn}>All Vps</span>
                  </label>

                  {listOptions.map((option, index) => (
                    <label key={index} className='select'>

                      <input
                        type="checkbox"
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionChange(option)}
                      />
                      <span style={{ paddingLeft: "10px", cursor: 'pointer' }} >{option}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div> */}




          <div className="table-wrapper" >

            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                <CircularProgress />
                <Typography sx={{ ml: 2 }}>Loading Vpn...</Typography>
              </Box>
            )
              : (



                <table className=''>
                  <thead>
                    <tr>
                      <th>Sr</th>
                      <th>ID</th>
                      {/* <th>Privatekey</th> */}
                      <th>Address</th>
                      {/* <th>D.N.S</th> */}
                      {/* <th>Public-key</th> */}
                      {/* <th>Preshared-key</th> */}
                      <th>Endpoints</th>
                      <th className='w-20'>Country</th>
                      <th>created_at</th>
                      <th>updated_at</th>
                      <th>is_enable</th>
                      <th className='' >Action</th>
                    </tr>
                  </thead>
                  <tbody>

                    <>

                      {allvpn ? (allvpn.map((data) => {
                        // delete Vpn 



                        return (

                          <tr key={data.id} className=''>
                            <td className='fw-bold'>{sr = sr + 1}</td>
                            <td >{data.id}</td>
                            <td>{data.address}</td>
                            <td>{data.endpoints}</td>

                            <td style={{ width: '12%' }}><input type="text" name="vpncountry" id="vpn country" value={data.country} className='form-control' readOnly /></td>

                            <td>{moment(data.created_at).format('MMMM Do YYYY, h:mm a')}</td>

                            <td>{moment(data.updated_at).format('MMMM Do YYYY, h:mm a')}</td>

                            {/* vpn dashboard Enable disable button */}
                            <td >
                              {/* {data.is_enable ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>} */}
                              {data.is_enable ? <><div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id={`is_enable${data.id}`} defaultChecked onClick={() => { Enablevpn(data.url, data.is_enable) }} />

                              </div></> :
                                <><div className="form-check form-switch">
                                  <input className="form-check-input" type="checkbox" id="{`is_enable${data.id}`}" onClick={() => { Enablevpn(data.url, data.is_enable) }} />

                                </div></>}
                            </td>


                            <td>

                              {/* Update VPN */}

                              <Link onClick={() => { ShareDataTOCanvas(data.url) }} className='me-2' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop" ><img src={update} alt="No logo" /></Link>

                              {/* Delete Vpn button  */}
                              <Link onClick={() => {

                                if (window.confirm('Are you sure you want to delete this vpn ?')) {
                                  axios.delete(data.url, { headers: { Authorization: AuthToken } }).then(() => {
                                    // alert('Vpn Delete')
                                    // window.location.reload()
                                    FetchVpnData()
                                  })
                                }
                              }
                              } ><img src={deleteLogo} alt="No logo" /></Link>


                            </td>

                          </tr>
                        );
                      })) : (
                        <h3>No data yet</h3>
                      )}


                    </>
                  </tbody>

                </table>


              )}
          </div>
        </div>




        {/* update  */}

        <div style={{ backgroundColor: "#bdd1f2" }} className=" offcanvas offcanvas-end" tabIndex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel" >
          <div className="offcanvas-header">
            <h5 id="offcanvasTopLabel">

            </h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div className="offcanvas-body ">
            <div className="container-fluid my-5 ">
              <h1 className='display-6 text-dark text-center'>Update Vpn</h1>
            </div>



            {/* <form className='px-5 ' onSubmit= action='#' > */}

            <div className="update-form">

              {/* Privatekey */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>Private Key</label>
                <input type="text" className='form-control' id='privatekey' name='privatekey' placeholder='Enter Private-Key' required value={privatekey} onChange={(e) => setPrivatekey(e.target.value)} />
              </div>

              {/* Address  */}
              <label htmlFor="" className='form-label'>Address</label>
              <div className="mb-3">
                <input type="text" className='form-control' id='address' name='address' placeholder='Enter IP-Address IPv4 : IPv6' required value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>

              {/* D.N.S  */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>D.N.S</label>
                <input type="text" className='form-control' id='dns' name='dns' placeholder='Enter D.N.S' required value={dns} onChange={(e) => setDns(e.target.value)} />
              </div>

              {/* Public Key */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>Public-Key</label>
                <input type="text" className='form-control' id='publickey' name='publickey' placeholder='Enter Public-key' required value={publickey} onChange={(e) => setPublickey(e.target.value)} />
              </div>

              {/* Preshared-key */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>Preshared-Key</label>
                <input type="text" className='form-control' id='presharedkey' name='presharedkey' placeholder='Enter Preshared-key' required value={presharedkey} onChange={(e) => setPresharedkey(e.target.value)} />
              </div>

              {/* Endpoints */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>Endpoints</label>
                <input type="text" className='form-control' id='endpoint' name='endpoint' placeholder='Enter Endpoint' required value={endpoint} onChange={(e) => setEndpoint(e.target.value)} />
              </div>

              {/* Allowed Ips */}
              <div className="mb-3">
                <label htmlFor="" className='form-label'>Allowed-Ips</label>
                <input type="text" className='form-control' id='allowed_ips' name='allowed_ips' placeholder='Enter Allowed-IP' required value={allowed_ips} onChange={(e) => setAllowed_ips(e.target.value)} />
              </div>

              {/* Country */}
              <div className="mb-4">
                <label htmlFor="" className='form-label'>Select Country </label>
                <Select defaultValue={""} options={options} type="text" name="vpncountry" id="vpn country" onChange={ChangeCountry} readOnly />
              </div>



              <div className="mb-3 text-center">
                <input type="submit" value="UPDATE VPN" className='btn btn-primary px-4 me-2' onClick={UpdateForm} data-bs-dismiss="offcanvas" />
                {/* <Link to='/vpndashboard' className='btn btn-secondary'>Cancle</Link> */}
                <button type="button" className="btn btn-secondary" data-bs-dismiss="offcanvas" >Cancle</button>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>

        <span>{ }</span>

      </div >


    </>
  )



}


