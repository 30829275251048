import React from 'react';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import './Login.css'

export default function Login() {
  if (localStorage.getItem('access_token') !== null) {
    window.history.back()
  }
  
  // const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const pass = document.getElementById('password');

  // to prevent display auth user 



  // Create the submit method.
  const SubmitLogin = async e => {
    e.preventDefault();
    const user = {
      username: username,
      password: password
    };
    // Create the POST requuest
    try {
      // 64.227.146.222
      
      const { data } = await axios.post('/token/', user, {headers:
          { 'Content-Type': 'application/json' }
      },
        { withCredentials: true });

      // Initialize the access & refresh token in localstorage.      
      localStorage.clear();
      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refresh_token', data.refresh);
      localStorage.setItem('username', username);

      axios.defaults.headers.common['Authorization'] = `Bearer ${data['access']}`;

      // console.log(localStorage)
      window.location.href = '/vpndashboard';

    } catch {
      alert('Check Username and Password .')
    }

  }

  // Show Password 
  const ShowPass = () => {
    if (document.getElementById('password').type === "password") {
      document.getElementById('password').type = 'text';
    }
    else {
      document.getElementById('password').type = 'password';
    }
  }

  // custom style 
  

  // span style
  const SpanStyle = {
    color: 'red'
  }
  return (
    <div className='container-fluid bg-light my-5 p-5' id='LoginStyle'>
      <form action="#" onSubmit={SubmitLogin}>
        <div className="mb-3">
          <label htmlFor="username" className="form-label">Username</label>
          <input type="text" className="form-control" id="username" required onChange={(event) => setUsername(event.target.value)} />
          <span style={SpanStyle} id='usernameMsg'></span>
        </div>
        <div className="mb-3">
          <label htmlFor="password" className="form-label">Password</label>
          <input type="password" className="form-control" id="password" required onChange={(event) => setPassword(event.target.value)} />
          <span style={SpanStyle} id='passwordMsg'></span>
        </div>

        <div className="mb-3 form-check">
          <input type="checkbox" className="form-check-input" id="showpass" onClick={ShowPass} />
          <label className="form-check-label" htmlFor="showpass">Show Password</label>
        </div>
        <button type='submit' className="btn btn-primary w-100">Login</button>
      </form>

    </div>
  )
}
