



import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import Navbar from '../Navbar';
import './DriveMediaDashboard.css'
import moment from 'moment/moment';
import { AuthToken } from '../..';  // auth Token 
import axios from 'axios';
import { Button, Card, Col, Divider, Input, Row, Spin, Typography, Table, notification, DatePicker, Modal, Alert, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';


const { RangePicker } = DatePicker;
const { Search } = Input;

const DriveMediaDashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [copiedText, setCopiedText] = useState(null);

    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    

    if (localStorage.getItem('access_token') === null) {
        window.location.href = '/'
    }

    useEffect(() => {
        FetchCustomerData()
    }, [])

    const FetchCustomerData = () => {
        axios.get('/api/d2m/get/cust/data/', { headers: { Authorization: AuthToken } })
            .then((response) => {
                setIsLoading(true)

                const data = response.data;
                setFilteredData(data);
                setData(data);


                setTimeout(() => {
                    setIsLoading(false)
                }, 500)

                // Clear the RangePicker values after filtering
                const rangePicker = document.querySelector('.ant-picker-range');
                if (rangePicker) {
                    rangePicker.clearSelection();
                }

            })
            .catch(() => {
                // FetchCustomerData()
                console.log("")
                // alert('Internet Connection Loss.')
            })
    }


    const handleFilter = () => {

        if (startDate && endDate) {

            const dates = {
                "start_date": startDate,
                "end_date": endDate
            }

            axios.post('api/d2m/get/cust/data/by/date/', dates, { headers: { Authorization: AuthToken } })
                .then((resdata) => {
                    setIsLoading(true)

                    // console.log(resdata.data)
                    const data = resdata.data;

                    setFilteredData(data);

                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500)
                }
                ).catch((err) => { alert('Server Not responding.') })
        }
        else {
            FetchCustomerData()
        }
    };

    const columns = [
        {
            title: 'Sr.',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (text, record, index) => index + 1,
            responsive: ['sm'],
        },
        {
            title: 'Customer Name',
            dataIndex: 'cus_name',
            key: 'cus_name',
            responsive: ['sm'],
            onCell: record => ({
                onClick: () => handleCellClick(record.cus_name),
            }),
        },
        {
            title: 'Customer Email',
            dataIndex: 'cus_email',
            key: 'cus_email',
            responsive: ['sm'],
            onCell: record => ({
                onClick: () => handleCellClick(record.cus_email),
            }),
        },
        {
            title: 'Services',
            dataIndex: 'cus_service',
            key: 'cus_service',
            responsive: ['md'],
            onCell: record => ({
                onClick: () => handleCellClick(record.cus_service),
            }),
        },
        {
            title: 'Customer Contact',
            dataIndex: 'cus_phone',
            key: 'cus_phone',
            responsive: ['md'],
            onCell: record => ({
                onClick: () => handleCellClick(record.cus_phone),
            }),
        },
        {
            title: 'Time Stamp',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text) => moment(text).format('MMMM Do YYYY, h:mm a'),
            responsive: ['lg'],
            onCell: record => ({
                onClick: () => handleCellClick(moment(record.created_at).format('MMMM Do YYYY, h:mm a')),
            }),
        },
    ];

    const customTableStyles = `
    .ant-table td {
    padding: 5px !important;
    margin: 0 !important;
    height: 20px !important;
    }

    .deleted-row {
    background-color: #ffccc7;
    }
    `;

    const handleCellClick = (text, columnName) => {
        if (text) {
            navigator.clipboard.writeText(text);
            setCopiedText(text);
            openNotification('success', `Text copied.`);
            setTimeout(() => {
                setCopiedText(null);
            }, 1500);
        } else {
            openNotification('warning', `Cannot copy empty field.`);
        }
    };

    const openNotification = (type, message) => {
        notification[type]({
            message,
            duration: 2, // Duration in seconds
        });
    };

    const onSearchData = (value, _e, info) => {
        let results = '';
        if (value) {
            results = data.filter((data) =>
                data.cus_name.toLowerCase().includes(value.toLowerCase()) ||
                data.cus_email.toLowerCase().includes(value.toLowerCase()) ||
                data.cus_service.toLowerCase().includes(value.toLowerCase()) ||
                (data.cus_phone && data.cus_phone.includes(value))
            );
            setFilteredData(results);
            setIsLoading(true);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        } else {
            FetchCustomerData();
        }
    };


    const handleYestersData = () => {


        axios.get('api/d2m/get/yesterdays/data/', { headers: { Authorization: AuthToken } })
            .then((response) => {
                setIsLoading(true)

                const data = response.data;
                setFilteredData(data);
                setData(data);


                setTimeout(() => {
                    setIsLoading(false)
                }, 500)



            })
            .catch((err) => {
                // FetchCustomerData()
                // console.log("")
                alert(err)

                // alert('Internet Connection Loss.')
            })
    }

    const handleTodaysData = () => {


        axios.get('api/d2m/get/todays/data/', { headers: { Authorization: AuthToken } })
            .then((response) => {
                setIsLoading(true)

                const data = response.data;
                setFilteredData(data);
                setData(data);


                setTimeout(() => {
                    setIsLoading(false)
                }, 500)



            })
            .catch((err) => {
                // FetchCustomerData()
                // console.log("")
                alert(err)
            })
    }


    // Handle Modal 
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleFileChange = (event) => {
        // Assuming you have an input field for file selection
        setSelectedFile(event.target.files[0]);
    };

    const handleOk = async () => {
        setIsModalOpen(false);

        if (!selectedFile) {
            // Handle the case where no file is selected
            // return (<Alert message="Upload Video." type="error" closable />);
            openNotification("error", "Upload Video.")
        }
        else {

            const formData = new FormData();
            formData.append('new_video', selectedFile);

            try {
                const response = await axios.patch('/api/d2m/update/video/data/', formData, {
                    headers: { Authorization: AuthToken }
                });

                console.log('Upload successful', response.data);
                openNotification("success", response.data)
                setSelectedFile(null)


            } catch (error) {
                console.error('Error uploading file', error);
                setSelectedFile(null)

                // return (<Alert message="Video upload fail." type="error" closable />);
                openNotification("error", "Error uploading file")

            }
        }
    };


  



    return (
        <>
            <Navbar />




            <div className="container my-4 text-center">
                <h1 className='display-5' style={{ color: 'primary', color: "white" }}>Drive2Media Panel</h1>

                
            </div>

            <Modal title="Change D2M Video" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <input type="file" className='form-control' onChange={handleFileChange} />


            </Modal>

            <div style={{ marginBottom: "3%" }}>
                <div style={{ width: '80%', margin: '10px auto', borderRadius: '10px', boxShadow: '2px 5px 15px gray' }}>
                    <Card>
                        <Row>
                            <Col xs={24} sm={12} md={6} lg={6}>
                                <div className='search-Col'>
                                    <Search
                                        placeholder="Search ..."
                                        onSearch={onSearchData}
                                        className='search-box'
                                        allowClear
                                        enterButton
                                    />
                                </div>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={18}>
                                <div className='btnContainer' style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <div className='date-filter-Col' style={{ marginRight: "10px" }}>
                                        <Button onClick={showModal}>Change D2M Video</Button>
                                    </div>

                                    <div className='date-filter-Col' style={{ marginRight: "10px" }}>
                                        <Button onClick={handleTodaysData} type="primary">Today's Data</Button>
                                    </div>

                                    <div className='date-filter-Col' style={{ marginRight: "10px" }}>
                                        <Button onClick={handleYestersData} type="primary">Yesterday's Data</Button>
                                    </div>

                                    <div className='date-filter-Col' style={{ marginRight: "10px" }}>
                                        <RangePicker allowClear value={[startDate, endDate]} onChange={(dates) => { setStartDate(dates[0]); setEndDate(dates[1]) }} />
                                        <Button onClick={handleFilter}>Filter</Button>
                                    </div>

                                    <div className='addApp-container'>
                                        <Button onClick={FetchCustomerData}>Reload</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </div>

                <div style={{ padding: '20px', width: '80%', margin: '0px auto', backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 5px 15px gray' }}>
                    <div style={{ margin: '10px 10px', marginTop: '20px' }}>All Customer Data</div>
                    <div className='UserTable-container'>
                        <Card>
                            {
                                isLoading
                                    ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 400 }}>
                                            <CircularProgress />
                                            <Typography sx={{ ml: 2 }}>Loading Data...</Typography>
                                        </div>
                                    )
                                    : (
                                        <div>
                                            <style>{customTableStyles}</style>
                                            <Table
                                                columns={columns}
                                                dataSource={filteredData}
                                                pagination={{ pageSize: 20, responsive: true }}
                                            />
                                        </div>
                                    )
                            }
                        </Card>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DriveMediaDashboard;
