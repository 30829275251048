import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { AuthToken } from '../..';
import update from '../../Images/Logo/update.png';
import deleteLogo from '../../Images/Logo/delete.png';
import Addapps from './Addapps';
// import upload from '../../Images/Logo/upload.png';
import Multiselect from 'multiselect-react-dropdown';
import { Box, Typography, Button, Grid, CircularProgress } from '@mui/material';

import '../Vpn/Vpntable.css'


export default function Appstable() {
    const [allapps, setAllapps] = useState([]);
    const [addStatus, setAddStatus] = useState(false);
    const [loading, setLoading] = useState(true);

    // const [editingRow, setEditingRow] = useState(null)


    // use to fetch all apps data from api and set AllVpn state  
    let asr = 0;
    useEffect(() => {
        FetchAppData();
    }, [])


    // fetch all apps data to siaplay the apps 
    const FetchAppData = () => {
        setLoading(true); // Show loading animation
        axios.get('/frontend/app/', { headers: { Authorization: AuthToken } })
            .then((Appresp) => {

                setTimeout(() => {
                    setAllapps(Appresp.data);
                    setLoading(false);// Hide loading animation
                    // console.log('allvpn :',Vpnresp.data)

                }, 2000)


            })
            .catch(() => {
                alert('Server Not responding to display data ')
            })
    }




    // use to show the add new option 
    const ShowAddApp = () => {
        if (addStatus) {
            setAddStatus(false)
        } else {
            setAddStatus(true)
        }
    }


    // # Edit Row start from here 
    const [inEditMode, setInEditMode] = useState({ status: false, rowKey: null });
    const [appLogo, setAppLogo] = useState('');
    const [appName, setAppName] = useState(null)
    const [packageName, setPackageName] = useState(null);
    const [options, setOptions] = useState([])
    const [country, setcountry] = useState('')
    const [allvpn, setAllvpn] = useState([])
    const [emptycountry, setEmptycountry] = useState(false)




    // fetch all vpn data for country selection 

    useEffect(() => {
        // fetch all vpn data for countries 
        axios.get('/frontend/vpn/', { headers: { Authorization: AuthToken } })
            .then((Vpnresp) => {
                setAllvpn(Vpnresp.data);

            })
            .catch(() => {
                alert('Server Not responding to display data ')
            })

    }, [])


    // this method is used to set uniq countries options 
    const setServerOptions = () => {
        // let countryServer = new Array();
        let countryServer = [];

        allvpn.forEach(country => { countryServer.push(country.country) })

        let uniqueServer = countryServer.filter((item, i, ar) => ar.indexOf(item) === i)
        setOptions(uniqueServer)

    }


    const onEdit = ({ id, currentAppName, currentPackageName, currentcountry_Server }) => {
        setInEditMode({
            status: true,
            rowKey: id
        })
        setAppName(currentAppName);
        setPackageName(currentPackageName);
        setcountry(currentcountry_Server)
        setServerOptions();


    }



    const onCancel = () => {
        // reset the inEditMode state value
        setInEditMode({
            status: false,
            rowKey: null
        })
        // reset the unit price state value
        setAppName(null);
        setPackageName(null);
        setAppLogo('');
        setcountry('');
    }

    // this method is used to update the app 

    const UpdateApps = ({ id }) => {

        const formData = new FormData();
        if (appLogo !== "") {
            formData.append('applogo', appLogo);
            setEmptycountry(false)
        }

        formData.append('appname', appName);
        formData.append('packagename', packageName);


        if (country !== "") {
            formData.append('country', country)
        }

        if (country == "" && emptycountry === true) {
            // console.log('country', 'Server Not Selected')
            setEmptycountry(false)
            formData.append('country', 'Server Not Selected')

        }


        // console.log(formData)

        axios.patch(`/frontend/app/${id}/`, formData, { headers: { Authorization: AuthToken } })
            .then((Appsresp) => {
                FetchAppData();
                onCancel();
                // console.log('allvpn :',Vpnresp.data)

            })
            .catch(() => {
                alert('Server Not responding to display data ')
            })

    }

    const onSave = ({ id }) => {

        UpdateApps({ id })
    }


    // console.log('allapps :' ,allapps)



    return (
        <>
            <div>
                <div className="container mt-4 text-light text-end" >
                    <Link onClick={ShowAddApp} className='btn btn-primary'>ADD NEW</Link>
                </div>

                <div className="container my-3 p-5 bg-light"  style={{borderRadius:'10px' ,boxShadow:'2px 5px 15px gray'}}>

                    <div className="table-wrapper">

                        {loading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200 }}>
                                <CircularProgress />
                                <Typography sx={{ ml: 2 }}>Loading Apps...</Typography>
                            </Box>
                        )
                            : (


                                <table className=''>
                                    <thead>
                                        <tr>
                                            <th>Sr</th>
                                            <th>App Logo</th>
                                            <th>App Name</th>
                                            <th>Package Name</th>
                                            <th>Server Location</th>


                                            <th className='' >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addStatus ? <Addapps /> : null}
                                        <>
                                            {allapps ? (allapps.map((data) => {
                                                // delete Vpn 



                                                return (

                                                    <tr key={data.id} className=''>

                                                        {/* serial Number  */}
                                                        <td className='fw-bold'>{asr = asr + 1}</td>

                                                        {/* applogo  td*/}
                                                        <td style={{ width: '18%' }}>

                                                            {
                                                                inEditMode.status && inEditMode.rowKey === data.id
                                                                    ?
                                                                    <><label htmlFor="applogo" className='border border-dark rounded-3'><img src={data.applogo} alt="No logo" height='40px' /></label>
                                                                        <input type='file' id='applogo' onChange={(event) => { setAppLogo(event.target.files[0]) }} style={{ display: 'none' }} />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input type='file' files={data.applogo} style={{ display: 'none' }} />
                                                                        <img src={data.applogo} alt="No logo" height='40px' />

                                                                    </>
                                                            }
                                                        </td>

                                                        {/* app name td  */}
                                                        <td style={{ width: '18%' }}>
                                                            {
                                                                inEditMode.status && inEditMode.rowKey === data.id
                                                                    ?
                                                                    (<input className='form-control' value={appName} onChange={(event) => { setAppName(event.target.value) }} />)
                                                                    :
                                                                    (data.appname)
                                                            }
                                                        </td>

                                                        {/* package name td  */}
                                                        <td style={{ width: '18%' }}>
                                                            {
                                                                inEditMode.status && inEditMode.rowKey === data.id
                                                                    ?
                                                                    (<input className='form-control' value={packageName} onChange={(event) => { setPackageName(event.target.value) }} />)
                                                                    :
                                                                    (data.packagename)
                                                            }
                                                        </td >

                                                        {/* select server td  */}
                                                        <td >
                                                            {
                                                                inEditMode.status && inEditMode.rowKey === data.id
                                                                    ?
                                                                    <>
                                                                        <Multiselect
                                                                            isObject={false}

                                                                            onRemove={(event) => { setcountry(event); setEmptycountry(true) }}

                                                                            onSelect={(event) => { setcountry(event) }}
                                                                            options={options}
                                                                            selectedValues={data.country.split(',')}
                                                                            defaultValue={data.country}
                                                                            showCheckbox={true}

                                                                        />

                                                                    </>
                                                                    :


                                                                    <input type="text" name="vpnserver" id="vpnserver" className='form-control' value={data.country} readOnly />
                                                            }
                                                        </td>



                                                        {/* Action td update and delete  */}
                                                        <td>

                                                            {/* Update VPN */}


                                                            {inEditMode.status && inEditMode.rowKey === data.id ?
                                                                (
                                                                    <React.Fragment>
                                                                        <button className={"btn btn-sm btn-success me-1"} onClick={() => onSave({ id: data.id })}  >Save</button>

                                                                        <button className={"btn btn-sm btn-secondary"} onClick={() => onCancel()} >Cancel</button>
                                                                    </React.Fragment>
                                                                )
                                                                :
                                                                (
                                                                    <>

                                                                        <Link className='me-2' onClick={() => onEdit({ id: data.id, currentAppName: data.appname, currentPackageName: data.packagename, currentcountry_Server: data.country })} ><img src={update} alt="No logo" /></Link>

                                                                        {/* Delete Vpn button  */}
                                                                        <Link onClick={() => {

                                                                            if (window.confirm('Are you sure you want to delete this App ?')) {
                                                                                axios.delete(data.url, { headers: { Authorization: AuthToken } }).then(() => {
                                                                                    // alert('Vpn Delete')
                                                                                    FetchAppData();
                                                                                })
                                                                            }
                                                                        }
                                                                        } ><img src={deleteLogo} alt="No logo" /></Link>

                                                                    </>
                                                                )
                                                            }


                                                        </td>

                                                    </tr>
                                                );
                                            })) : (
                                                <h3>No data yet</h3>
                                            )}
                                        </>

                                    </tbody>
                                </table>

                            )}
                    </div>
                </div>


            </div>

        </>
    )
}
