
import React, { useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import './Marketing.css'
import Navbar from '../Navbar';
import { DropzoneArea } from 'material-ui-dropzone';
// import { Button, Typography } from '@mui/material';
import { Button, Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Modal, notification } from 'antd'

// CSV Files
import camp_file from '../../csv_files/campaign_file1.csv'
import rev_file from '../../csv_files/revenue_file1.csv'

import { AuthToken } from '../..';  // auth Token 
import axios from 'axios';

import { useDropzone } from 'react-dropzone';

// import { storeRecord } from './MarketingData'

const campaignStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const tableHeaderStyle = {
    background: '#f2f2f2',
    padding: '10px',
    textAlign: 'left',
    cursor: "pointer"
};

const tableCellStyle = {
    padding: '10px',
    borderBottom: '1px solid #ddd',
};

const MarketingDashboard = () => {

    if (localStorage.getItem('access_token') === null) {
        window.location.href = '/'
    }
    const [copiedText, setCopiedText] = useState(null);


    // campaign csv file operation 
    const [campainModalopen, setCampainModalOpen] = React.useState(false);
    const handleCampainModalOpen = () => {
        parseCsvCampainFile(camp_file);
        setCampainModalOpen(true)
    };

    const handleCampainModalClose = () => setCampainModalOpen(false);
    const [csvCampainData, setCsvCampainData] = useState([]);

    const parseCsvCampainFile = (file) => {
        fetch(file)
            .then((response) => response.text())
            .then((text) => {
                const data = text.split('\n').map(row => row.split(','));
                setCsvCampainData(data);
            })
            .catch((error) => {
                console.error('Error loading CSV file:', error);
            });
    };
    const filteredCsvCampainData = csvCampainData.filter(row => row.some(cell => cell.trim() !== ''));


    // revenue csv file operation 
    const [revenueModalopen, setRevenueModalOpen] = React.useState(false);
    const handleRevenueModalOpen = () => {
        parseCsvRevenueFile(rev_file)
        setRevenueModalOpen(true)
    };
    const handleRevenueModalClose = () => setRevenueModalOpen(false);
    const [csvRevenueData, setCsvRevenueData] = useState([]);

    const parseCsvRevenueFile = (file) => {
        fetch(file)
            .then((response) => response.text())
            .then((text) => {
                const data = text.split('\n').map(row => row.split(','));
                setCsvRevenueData(data);
            })
            .catch((error) => {
                console.error('Error loading CSV file:', error);
            });
    };
    const filteredCsvRevenueData = csvRevenueData.filter(row => row.some(cell => cell.trim() !== ''));



    const [campaignFile, setCampaignFile] = useState(null);
    const [revenueFile, setRevenueFile] = useState(null);
    const [currencyRate, setCurrencyRate] = useState('');

    // Errors
    const [campaignFileError, setCampaignFileError] = useState(false);
    const [revenueFileError, setRevenueFileError] = useState(false);
    const [currencyRateError, setCurrencyRateError] = useState(false);

    const [storeRecord, setStoreRecord] = useState({})

    const [showTable, setShowTable] = useState(false)
    const [totalCost, setTotalCost] = useState(0)
    const [totalRevenue, setTotalRevenue] = useState(0)
    const [netProfit, setNetProfit] = useState(0)


    // # Cel Auto copy 
    const handleCellClick = (text, columnName) => {
        // console.log("text :", text)
        if (text) {
            navigator.clipboard.writeText(text);
            setCopiedText(text);
            openNotification('success', `${text} copied.`);
            setTimeout(() => {
                setCopiedText(null);
            }, 1500);
        } else {
            openNotification('warning', `Cannot copy empty field.`);
        }
    };

    const openNotification = (type, message) => {
        notification[type]({
            message,
            duration: 2, // Duration in seconds
        });
    };


    const CampaignDropzone = ({ onDrop, maxHeight }) => {
        const { getRootProps, getInputProps } = useDropzone({
            accept: '.csv',
            onDrop,
            multiple: false,
        });

        return (
            <div
                {...getRootProps()}
                style={{
                    minHeight: maxHeight,
                    border: '2px dashed #cccccc',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    marginTop: '10px',
                }}
            >
                {/* <input {...getInputProps()} />
                <p>Drag and drop or click to upload Campaign CSV file</p> */}

                <input {...getInputProps()} />
                {
                    campaignFile ?
                        <p style={{ marginTop: "10px", color: '#1976D2' }}><FilePresentIcon style={{ marginRight: '10px' }} />{campaignFile.name}</p>
                        :
                        // <p style={{marginTop:"10px"}}><CloudUploadIcon style={{marginRight:'10px'}} />Drag and drop or click to upload Campaign CSV file</p>
                        <p style={{ marginTop: "10px" }}><CloudUploadIcon style={{ marginRight: '10px', color: '#2196F3' }} />Upload Campaign (Marketing) CSV</p>
                }
            </div>
        );
    };


    const RevenueDropzone = ({ onDrop, maxHeight }) => {
        const { getRootProps, getInputProps } = useDropzone({
            accept: '.csv',
            onDrop,
            multiple: false,
        });

        return (
            <div
                {...getRootProps()}
                style={{
                    minHeight: maxHeight,
                    border: '2px dashed #cccccc',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    // marginTop : '10px',
                }}
            >
                {/* <p>Drag and drop or click to upload Revenue CSV file</p> */}

                <input {...getInputProps()} />
                {
                    revenueFile ?

                        <p style={{ marginTop: "10px", color: 'green' }}><FilePresentIcon style={{ marginRight: '10px' }} />{revenueFile.name}</p>
                        :
                        // <p style={{marginTop:"10px"}}><CloudUploadIcon style={{marginRight:'10px'}} />Drag and drop or click to upload Revenue CSV file</p>
                        <p style={{ marginTop: "10px" }}><CloudUploadIcon style={{ marginRight: '10px', color: '#2196F3' }} />Upload Revenue CSV</p>
                }
            </div>


        );
    };


    const handleCampaignFileUpload = (files) => {
        // Assuming user can upload only one file for campaign
        if (files.length > 0) {
            setCampaignFile(files[0]);
        }
    };

    const handleRevenueFileUpload = (files) => {
        // Assuming user can upload only one file for revenue
        if (files.length > 0) {
            setRevenueFile(files[0]);
        }
    };

    const handleCurrencyRateChange = (event) => {
        setCurrencyRate(event.target.value);
    };

    const handleProcessClick = () => {
        

        if (!campaignFile || !revenueFile || currencyRate === "") {
            // alert(`Campaign File: ${campaignFile.name}`)
            if (!campaignFile) {

                // alert('Upload Campaign File ...')
                setCampaignFileError(true)
            }


            else if (!revenueFile) {

                // alert('Upload Revenue File ...')
                setCampaignFileError(false)
                setRevenueFileError(true)
            }

            else if (currencyRate === "") {

                // alert('Input Currency Rate ...')
                setCampaignFileError(false)
                setRevenueFileError(false)
                setCurrencyRateError(true)
            }

            console.log(campaignFileError, revenueFileError, currencyRateError)

        }
        else {
            setCampaignFileError(false)
            setRevenueFileError(false)
            setCurrencyRateError(false)


            const formData = new FormData();
            formData.append('campaign_file', campaignFile);
            formData.append('revenue_file', revenueFile);
            formData.append('currency_rate', currencyRate);


            axios.post('/marketapp/api/upload/', formData, { headers: { Authorization: AuthToken } })
                .then((appResp) => {
                    
                    if (appResp?.data?.message) {
                        // Success case
                        setStoreRecord(appResp.data.record);
                        setTotalCost(appResp.data.total_cost);
                        setTotalRevenue(appResp.data.total_revenue);
                        setNetProfit(appResp.data.net_profit);
                        setShowTable(true);
                    } if (appResp.response) {
                        // Handle error case
                        // console.log("Error :", appResp.response.data.error)
                        alert(appResp.response.data.error)
                        // You can display an error message to the user, for example:
                        // setErrorMsg("Error: " + appResp.data.error);
                    }
                })
                .catch((err) => {
                    console.log("err", err)
                    // alert('Server Not responding ')
                })

        }

    };

   



    return (
        <>
            <Navbar />
            <div className="container my-4  text-center">
                <h1 className='display-5' style={{ color: 'primary' }}>MARKETING PANEL</h1>
            </div>



            <div style={{ padding: '20px', width: '80%', margin: '0px auto', backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 5px 15px gray' }}>
                <div style={{ display: 'flex' }}>
                    {/* First part */}
                    <div style={{ flex: 1, margin: '5px' }} id='campaign-component'>
                        <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Campaign Report  <span style={{ color: "red" }}>*</span></Typography>
                        {/* <DropzoneArea acceptedFiles={['.csv']} onChange={handleCampaignFileUpload} maxHeight={150} /> */}
                        <CampaignDropzone onDrop={handleCampaignFileUpload} maxHeight={50} />
                        {
                            campaignFileError ?
                                <span style={{ color: 'red' }}>Upload Campaign file .</span>
                                :
                                ''
                        }

                    </div>

                    {/* Second part with DropzoneArea */}
                    <div style={{ flex: 1, margin: '5px' }} id='revenue-component'>
                        <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Revenue Report  <span style={{ color: "red" }}>*</span></Typography>
                        {/* <DropzoneArea acceptedFiles={['.csv']} onChange={handleRevenueFileUpload} maxHeight={10}/> */}

                        {/* <DropzoneArea
                            acceptedFiles={['.csv']}
                            onChange={handleRevenueFileUpload}
                            maxHeight={150}
                        // other props...
                        /> */}

                        <RevenueDropzone onDrop={handleRevenueFileUpload} maxHeight={50} />

                        {
                            revenueFileError ?
                                <span style={{ color: 'red' }}>Upload Revenue file .</span>
                                :
                                ''
                        }

                    </div>

                    {/* Third part for currency rate */}
                    <div style={{ flex: 1, margin: '5px' }} id='currency-rate-component'>
                        <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Currency Rate ($) <span style={{ color: "red" }}>*</span></Typography>
                        <div style={{ marginTop: '20px' }}>
                            <input type="text" className='form-control' placeholder='eg. 81.22' onChange={handleCurrencyRateChange} />
                        </div>
                        {
                            currencyRateError ?
                                <span style={{ color: 'red' }}>Input Currency Rate .</span>
                                :
                                ''
                        }

                    </div>


                </div>



                <div style={{ display: 'flex' }}>
                    {/* First part */}
                    <div style={{ flex: 1, margin: '5px' }} id='campaign-component'>
                        {/* <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Campaign Report  </span></Typography> */}

                        <Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleCampainModalOpen}>Example Campaign File</Button>
                    </div>

                    {/* Second part with DropzoneArea */}
                    <div style={{ flex: 1, margin: '5px' }} id='revenue-component'>
                        {/* <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Revenue Report  <span style={{ color: "red" }}>*</span></Typography> */}
                        <Button sx={{ textTransform: "none" }} variant="outlined" onClick={handleRevenueModalOpen}>Example Revenue File</Button>

                    </div>

                    {/* Third part for currency rate */}
                    <div style={{ flex: 1, margin: '5px' }} id='currency-rate-component'>
                        {/* <Typography style={{ margin: '10px', fontWeight: 'bold' }}>Currency Rate ($) <span style={{ color: "red" }}>*</span></Typography> */}


                    </div>


                </div>

                <div style={{ margin: '20px', textAlign: 'center' }}>
                    <Button onClick={handleProcessClick} color="primary" variant="contained" >Calculate</Button>

                </div>
            </div>


            {/* campaign Modal  */}
            <Modal
                title="Example File for Campaign"
                open={campainModalopen}
                onOk={handleCampainModalOpen}
                onCancel={handleCampainModalClose}
                footer={null}
                width={1200}

            >
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            {filteredCsvCampainData.length > 0 &&
                                filteredCsvCampainData[0].map((header, index) => (
                                    <th key={index} style={tableHeaderStyle} onClick={() => { handleCellClick(header) }} title='click to copy text'>{header}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCsvCampainData.length > 1 &&
                            filteredCsvCampainData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={tableCellStyle}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Modal>



            {/* revenue Modal  */}
            <Modal
                title="Example File for Revenue"
                open={revenueModalopen}
                onOk={handleCampainModalOpen}
                onCancel={handleRevenueModalClose}
                footer={null}

            >
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                            {filteredCsvRevenueData.length > 0 &&
                                filteredCsvRevenueData[0].map((header, index) => (
                                    <th key={index} style={tableHeaderStyle} onClick={() => { handleCellClick(header) }} title='click to copy text'>{header}</th>
                                ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCsvRevenueData.length > 1 &&
                            filteredCsvRevenueData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={tableCellStyle}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                    </tbody>
                </table>
            </Modal>




            {/* <div style={{ padding: '20px', width: '80%', margin: '0px auto', backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 5px 15px gray', marginTop: '20px', marginBottom: '20px' }} > */}
            {
                // storeRecord.App ?
                storeRecord.campaign ?
                    // showTable  ?
                    <div>
                        <div style={{ padding: '20px', width: '80%', margin: '0px auto', backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 5px 15px gray', marginTop: '20px', marginBottom: '20px' }}>
                            <div style={{ margin: "10px auto" }}>
                                <Typography style={{ fontWeight: 'bold' }}>Overview</Typography>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <div style={{ flex: 1, padding: '10px', margin: '0px 10px', boxShadow: '2px 2px 5px gray', borderRadius: '5px', textAlign: "center" }}>
                                    <Typography>Total Cost </Typography>

                                    <p className='lead' style={{ fontWeight: 'bold' }}>
                                        ₹ {totalCost.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                                    </p>

                                </div>

                                <div style={{ flex: 1, padding: '10px', margin: '0px 10px', boxShadow: '2px 2px 5px gray', borderRadius: '5px', textAlign: "center" }}>
                                    <Typography>Total Revenue </Typography>
                                    {/* <p className='lead' style={{ fontWeight: "bold" }}>₹ {totalRevenue}</p> */}

                                    <p className='lead' style={{ fontWeight: 'bold' }}>
                                        ₹ {totalRevenue.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                                    </p>
                                </div>


                                <div style={{ flex: 1, padding: '10px', margin: '0px 10px', boxShadow: '2px 2px 5px gray', borderRadius: '5px', textAlign: "center" }}>
                                    {
                                        netProfit >= 0 ?
                                            <Typography>Net Profit </Typography>
                                            :
                                            <Typography>Net Loss </Typography>
                                    }
                                    {/* <p className='lead' style={{ fontWeight: "bold" }}>₹ {netProfit}</p> */}

                                    <p className='lead' style={{ fontWeight: 'bold' }}>

                                        ₹ {netProfit.toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                                    </p>
                                </div>

                            </div>

                        </div>



                        <div style={{ padding: '20px', width: '80%', margin: '0px auto', backgroundColor: 'white', borderRadius: '10px', boxShadow: '2px 5px 15px gray', marginTop: '20px', marginBottom: '20px' }}>
                            <div style={{ margin: "10px auto" }}>
                                <Typography style={{ fontWeight: 'bold' }}>Report</Typography>
                            </div>

                            <table className='table table-hover'>
                                <thead>
                                    <tr>
                                        <th>Campaign</th>
                                        <th>eCPM ( $ )</th>
                                        <th>Country</th>
                                        <th>Cost ( ₹ ) </th>
                                        <th>Cost / conv ( ₹ )</th>
                                        <th>Revenue ( $ )</th>
                                        <th>Profit ( ₹ ) </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {storeRecord.campaign.map((app, index) => (
                                        <tr key={index}>
                                            <td>{app}</td>
                                            <td>{storeRecord.ecpm[index]}</td>
                                            <td>{storeRecord.country[index]}</td>
                                            <td>{storeRecord.cost[index]}</td>
                                            <td>{storeRecord['cost/conv'][index]}</td>
                                            <td>{storeRecord.revenue[index]}</td>
                                            {
                                                storeRecord.profit[index] > 0 ?
                                                    // <td style={{ backgroundColor: 'green' }}><strong>{storeRecord.profit[index]}</strong> </td>
                                                    <td><strong>{storeRecord.profit[index]}</strong> </td>

                                                    :
                                                    <td><strong>{storeRecord.profit[index]}</strong> </td>
                                            }
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>



                    </div>

                    :

                    <Box sx={{ margin: '0px auto' }}>
                        {/* <CircularProgress />
                            <Typography sx={{ ml: 2 }}>Loading Data...</Typography> */}
                    </Box>


            }

            {/* </div > */}


        </>
    );
}

export default MarketingDashboard;
